import { graphql } from 'gatsby';
import React, { FC } from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import styles from './maison-mosaic.module.scss';
import Back from '../images/FinishesLibraryHero.jpg';
import ImageWithThumbnailCard from '../components/custom/ImageWithThumbnailCard/ImageWithThumbnailCard';

export const query = graphql`
  {
    strapi {
      finishesLibraryItems {
        ShowOnName
        Name
        CloseupImage {
          caption
          url
          alternativeText
          id
        }
        MainImage {
          url
          alternativeText
          caption
          id
        }
      }
    }
  }
`;

interface Props {
  data: {
    strapi: {
      finishesLibraryItems: [
        {
          ShowOnName: string;
          Name: string;
          id: string;
          CloseupImage: {
            caption: string;
            url: string;
            alternativeText: string;
            id: string;
          };
          MainImage: {
            url: string;
            alternativeText: string;
            caption: string;
            id: string;
          };
        },
      ];
    };
  };
}

const FinishesLibrary: FC<Props> = ({
  data: {
    strapi: { finishesLibraryItems },
  },
}: Props) => {
  const sortedFinishesLibraryItems = finishesLibraryItems.sort((a, b) =>
    a.Name.localeCompare(b.Name),
  );

  return (
    <Layout isWhiteBackground={true}>
      <SEO title="Finishes Library" />
      <section className={styles.wrapper}>
        <div className={styles.header}>
          <img src={Back} className={styles.image} />
          <div className={styles.border}></div>
          <div className={styles.titleContainer}>
            <p className={styles.title}>{'Natural Stone'}</p>
            <p className={styles.text}>FINISHES LIBRARY</p>
          </div>
        </div>

        <section className={styles.tilesWrapper}>
          <div className={styles.tilesContainer}>
            {sortedFinishesLibraryItems?.map((item, i) => (
              <div key={i + `${item.MainImage} ${Date.now()}`} className={styles.tile}>
                <ImageWithThumbnailCard
                  image={item.MainImage}
                  name={item.Name}
                  imgIndex={i}
                  smallImg={item.CloseupImage.url}
                  finish={item.ShowOnName}
                  unclickable
                />
              </div>
            ))}
          </div>
        </section>
      </section>
    </Layout>
  );
};

export default FinishesLibrary;
