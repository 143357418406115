import React, { FC } from 'react';
import styles from './ImageWithThumbnailCard.module.scss';

interface Props {
  image: {
    url: string;
  };
  name: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  imgIndex?: number;
  smallImg: string;
  finish?: string;
  unclickable?: boolean;
}

const ImageWithThumbnailCard: FC<Props> = ({
  image,
  name,
  onClick,
  imgIndex,
  smallImg,
  finish,
  unclickable,
}: Props) => {
  return (
    <section className={styles.wrapper}>
      <div className={styles.imagesWrap}>
        {image !== null && (
          <img
            src={image.url}
            alt="tile"
            className={styles.image}
            data-index={imgIndex}
            onClick={onClick}
            style={{
              cursor: unclickable ? 'auto' : 'pointer',
            }}
          />
        )}
        {smallImg !== null && (
          <div
            className={styles.thumbnailWrap}
            onClick={onClick}
            style={{
              cursor: unclickable ? 'auto' : 'pointer',
            }}
          >
            <img
              src={smallImg}
              alt="tile"
              className={styles.imageThumbnail}
              data-index={imgIndex}
            />
          </div>
        )}
      </div>
      {name !== null && <p className={styles.name}>{name}</p>}
      {finish !== null && <p className={styles.finish}>{finish}</p>}
    </section>
  );
};

export default ImageWithThumbnailCard;
